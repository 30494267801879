import React, { FC, useContext, useState } from 'react';
import { Box, Button, Collapse, Stack, Tooltip, Typography } from '@mui/material';
import ExpandMoreButton from 'components/buttons/ExpandMoreButton';
import { useOutletContext } from 'react-router-dom';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';
import { createUserName, formatISODate } from 'helpers/formaters';
import { AuthContext } from 'context/authContext';
import { QuestionElementLkDto, UserAnswerStatus } from '@generated/graphql';

export type HistoryItem = {
  __typename?: 'UserAnswerHistoryLkDto';
  id: string;
  updatedAt: any;
  message?: string | null;
  score?: number | null;
  newStatus?: UserAnswerStatus | null;
  files?: Array<{ __typename?: 'UserAnswerHistoryFileLkDto'; id: string; file: { __typename?: 'FileLkDto'; url: string; name: string } }> | null;
  user?: { __typename?: 'PreviewUserLkDto'; id: string; firstName?: string | null; lastName?: string | null } | null;
};
const CollapsedCard: FC<{ currentElement?: QuestionElementLkDto; historyItem?: HistoryItem }> = ({ currentElement, historyItem }) => {
  const { me } = useContext(AuthContext);
  const { subject } = useOutletContext<IExerciseOutletContext>();
  const [open, setOpen] = useState<boolean>(false);
  const files = currentElement?.currentUserAnswers?.[0]?.files || historyItem?.files;
  const userTextAnswer = currentElement?.currentUserAnswers?.[0]?.answer;
  const isMe = me?.id === historyItem?.user?.id;
  return (
    <Box component='div' sx={{ position: 'relative' }}>
      <Stack
        direction='row'
        justifyContent='space-between'
        spacing={1}
        onClick={() => setOpen((prev) => !prev)}
        sx={{ bgcolor: 'baseShift.400', borderRadius: '10px', p: 1.5, position: 'relative', zIndex: 1, cursor: 'pointer' }}
      >
        <Typography variant='text4'>{historyItem ? createUserName(historyItem?.user) : 'Задание'}</Typography>
        <Box component='div'>
          {!!historyItem && !isMe && (
            <Typography variant='text4' mr={5} sx={{ color: 'base.400' }}>
              куратор
            </Typography>
          )}
          <ExpandMoreButton isOpen={open} sx={{ right: 12, top: 1 }} />
        </Box>
      </Stack>
      <Collapse in={open} sx={{ top: -12, position: 'relative', zIndex: 0 }}>
        <Box component='div' sx={{ bgcolor: 'baseShift.500', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', boxShadow: 4, p: 1.5, pt: 3 }}>
          {(currentElement?.title || historyItem?.message) && (
            <Typography
              variant='text4'
              sx={{
                whiteSpace: 'pre-wrap',
                mb: 3,
              }}
              dangerouslySetInnerHTML={{
                __html: currentElement?.title || historyItem?.message || '',
              }}
            />
          )}
          {currentElement?.description && (
            <Typography
              variant='text4'
              sx={{
                whiteSpace: 'pre-wrap',
                mb: 3,
              }}
              dangerouslySetInnerHTML={{
                __html: currentElement?.description,
              }}
            />
          )}
          {!!currentElement && (
            <Typography variant='text4' sx={{ mb: 1 }}>
              Ответ:
            </Typography>
          )}
          {!!userTextAnswer && (
            <Typography variant='text4' sx={{ mb: 1 }}>
              {userTextAnswer}
            </Typography>
          )}
          {!!files?.length && (
            <Stack direction='row' spacing={1.5}>
              {files.map((file) => (
                <Tooltip title={file?.file.name} key={file.file?.name}>
                  <Button variant='contained' color='primary' subject={subject} size='small' href={file.file?.url}>
                    {file?.file.name && file.file.name?.length > 8 ? `${file.file.name.slice(0, 8)}...` : file.file.name}
                  </Button>
                </Tooltip>
              ))}
            </Stack>
          )}

          {historyItem?.updatedAt && (
            <Typography
              variant='text4'
              sx={{
                color: 'base.400',
                mt: 1.5,
              }}
            >
              {formatISODate(historyItem.updatedAt, 'dd.MM.yyyy HH:mm')}
            </Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapsedCard;
